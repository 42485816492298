export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const activeCheckbox = require("../assets/activeCheckbox.png");
export const disableCheckbox = require("../assets/disableCheckbox.png");
export const backButton = require("../assets/backButton.png");
export const cardType = require("../assets/cardType.png");
export const divider = require("../assets/divider.png");
export const eye = require("../assets/visibility.png");
export const eyeOff = require("../assets/visibility_off.png");
export const successIcon = require("../assets/success.png");

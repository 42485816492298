import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, InputAdornment } from "@mui/material";
import { activeCheckbox, disableCheckbox, backButton, cardType, divider, eye, eyeOff, successIcon } from "./assets";
import TextField from '@mui/material/TextField'
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start  
  BillingTable = () => {
    return (
      <TableContainer >
        <Table style={{ border: "1px solid #E2E8F0" }}>
          <TableHead>
            <TableRow>
              {
                this.BillingTableHeader.map((title, index) =>
                  <TableCell align={index == 0 ? "left" : "center"} style={{ ...CustomFontVariant.palette.font14700, lineHeight: "22px", color: "#64748B" }}>{title}</TableCell>
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {this.BillingTableBody.map((row) => (
              <TableRow>
                <Row align="left">${row.amount}</Row>
                <Row align="center">{row.plan}</Row>
                <Row align="center">{row.date}</Row>
                <Row align="center">{row.card}</Row>
                <Row align="right" style={{ fontWeight: 700 }}>{row.text}</Row>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  AddCardForm = (fieldWidth: number = 4) => {
    return (
      <>
        <Box
          data-test-id="addCardForm"
          component="form"
          noValidate
          onSubmit={(event: any) => {
            event.preventDefault();
            this.validateForm();
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            margin: 'auto',
            paddingTop: "10px",
          }}
        >
          <Grid container spacing={2} style={{ gap: "108px", marginBottom: "10px", ...(fieldWidth === 5 && {  display: 'flex', justifyContent: "space-between" }) }}>
            <Grid item xs={fieldWidth} >
              <FormLabel>Card number</FormLabel>
              <TextField
                data-test-id="cardNumber"
                name="cardNumber"
                type="text"
                variant="outlined"
                placeholder="Card number"
                sx={CardFormField}
                inputProps={{ maxLength: 16 }}
                onChange={(event) => this.handleChange(event, 'cardNumber')}
                error={!!this.state.cardFormError.cardNumber}
                helperText={this.state.cardFormError?.cardNumber}
                autoComplete="new-password"
                required
              />
            </Grid>
            <Grid item xs={fieldWidth} >
              <FormLabel>Name of card</FormLabel>
              <TextField
                data-test-id="cardName"
                name="cardName"
                type="text"
                variant="outlined"
                placeholder="Name of card"
                sx={CardFormField}
                onChange={(event) => this.handleChange(event, 'cardName')}
                error={!!this.state.cardFormError.cardName}
                helperText={this.state.cardFormError.cardName}
                autoComplete="new-password"
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ gap: "108px", marginBottom: "10px", ...(fieldWidth === 5 && {  display: 'flex', justifyContent: "space-between" }) }}>
            <Grid item xs={fieldWidth}>
              <FormLabel>Expiration date</FormLabel>
              <TextField
                data-test-id="expiryDate"
                name="expiryDate"
                variant="outlined"
                type="text"
                placeholder="Expiration date (MM/YY)"
                sx={CardFormField}
                onChange={(event) => this.handleChange(event, 'expiryDate')}
                error={!!this.state.cardFormError.expiryDate}
                helperText={this.state.cardFormError.expiryDate}
                required
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={fieldWidth} >
              <FormLabel>CVV</FormLabel>
              <TextField
                data-test-id="cvv"
                name="cvv"
                variant="outlined"
                value={this.state.cvv}
                placeholder="CVV"
                type={this.state.showCVV ? "text" : "password"}
                sx={CardFormField}
                onChange={(event) => this.handleChange(event, 'cvv')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          '&:hover': {
                            backgroundColor: 'white',
                          },
                        }}
                        onClick={() => this.setState({ showCVV: !this.state.showCVV })} edge="end">
                        {this.state.showCVV ? <img src={eye} /> : <img src={eyeOff} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 3 }}
                error={!!this.state.cardFormError.cvv}
                helperText={this.state.cardFormError.cvv}
                defaultValue=""
                autoComplete="new-password"
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ gap: "108px", marginBottom: "10px", ...(fieldWidth === 5 && {  display: 'flex', justifyContent: "space-between" }) }}>
            <Grid item xs={fieldWidth}>
            </Grid>
            <Grid item xs={fieldWidth} style={{ display: "flex", justifyContent: "end" }} >
              {!this.state.isView && <AddCardBtn >{this.state.isEdit ? "save" : "Add card"}</AddCardBtn>}
            </Grid>
          </Grid>
        </Box>
      </>)
  }

  SavedCard = (cardNumber: string) => {
    return (
      <CardBox>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 25px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: '10px' }}>
            <RadioGroup
              defaultValue="female"
              aria-labelledby="demo-customized-radios"
              name="customized-radios"
            >
              <FormControlLabel value="card" control={<CustomRadio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
              />} label="" />
            </RadioGroup>
            <div style={{ display: "flex", flexDirection: "column", gap: '4px' }}>
              <CardNumber>{cardNumber}</CardNumber>
              <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                <CardContent>
                  <CardAction data-test-id="view" onClick={this.handleView}>View</CardAction>
                  <img src={divider} alt="divider" />
                </CardContent>
                <CardContent>
                  <CardAction data-test-id="edit" onClick={this.handleEdit}>Edit</CardAction>
                  <img src={divider} alt="divider" />
                </CardContent>
                <CardContent>
                  <CardAction data-test-id="delete" onClick={this.handleDelete}>Delete</CardAction>
                </CardContent>
              </div>
            </div>
          </div>
          <img src={cardType} alt="cardType" />
        </div>
      </CardBox>
    )
  }

  NoCardUI = () => {
    return (
      <>
      {(this.state.cardDetails.length === 0 && !this.state.isAddCard) && <NoCardBox>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ ...CustomFontVariant.palette.font16700 }}>You don't have any cards added yet.</p>
            <AddButton data-test-id="addCard" onClick={this.handleAddCard}>+ Add card</AddButton>
          </div>
        </NoCardBox>}
      </>
    )
  }

  PaymentMethod = () => {
    return (
      <>
        <div style={{ margin: "50px" }}>
          <PaymentMethodTitle>Choose payment method</PaymentMethodTitle>
          {
            this.state.cardDetails.length > 0 && this.state.cardDetails.map(card => this.SavedCard(card.cardNumber))
          }
          {this.deleteCard()}
          {(this.state.isAddCard || this.state.isEdit || this.state.isView) && this.AddCardForm()}
        </div>
        {this.NoCardUI()}
      </>
    )
  }

  deleteCard() {
    return (
      <StyledDialog data-test-id="menuList-dialog" onClose={this.deleteCardDetails} open={this.state.isDeleteOpen} aria-labelledby="customized-dialog-title" >
        <DialogTitle>Delete Card</DialogTitle>
        <DialogContent>
          <Typography style={{ ...CustomFontVariant.palette.font24400, lineHeight: "32px", color: "#334155" }}>
            Are you sure you want to delete your card?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CancelButton data-test-id="cancel-btn" onClick={this.deleteCardDetails}>
            Cancel
          </CancelButton>
          <ConfirmButton data-test-id="delete-btn" onClick={() => this.setState({cardDetails: [], isAddCard: false, isEdit: false, isView: false, isDeleteOpen: false})}>
            Delete
          </ConfirmButton>
        </DialogActions>
      </StyledDialog>
    )
  }
  
  SubscribeUI() {
    return (
      <Main1>
            <Heading>
              Payment method
            </Heading>
            <Typography style={{ ...CustomFontVariant.palette.font16400, margin: '10px 0 20px' }}>
              All transactions are secure and encrypted.
            </Typography>
            {!this.state.isPay && <Grid container spacing={2} style={{ gap: "30px" }}>
              <Grid item xs={6} style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
                <MainContainer1>
                  <PaymentMethodTitle>Choose payment method</PaymentMethodTitle>
                  {this.state.cardDetails.length > 0 && this.SavedCard('****8239')}
                  {(this.state.isAddCard || this.state.isEdit || this.state.isView) && this.AddCardForm(5)}
                  {this.NoCardUI()}
                </MainContainer1>
                <PayNowBtn data-test-id="payNowBtn" onClick={() => this.setState({isPay: true})}>Pay now</PayNowBtn>
              </Grid>
              <Grid item xs={4}>
                <MainContainer1 style={{ height: "260px", paddingTop: '50px' }}>
                  <SubscriptionDetails>
                    <DetailLabel>Description</DetailLabel>
                    <DetailValue>Premium</DetailValue>
                  </SubscriptionDetails>
                  <SubscriptionDetails>
                    <DetailLabel>Subtotal</DetailLabel>
                    <DetailValue>$1,080.00</DetailValue>
                  </SubscriptionDetails>
                  <Divider />
                  <SubscriptionDetails>
                    <DetailLabel>Total</DetailLabel>
                    <TotalAmount>$1,083.00</TotalAmount>
                  </SubscriptionDetails>
                </MainContainer1>
              </Grid>
            </Grid>}
            {
              this.state.isPay && <SuccessBox>
                <img src={successIcon} alt="successIcon" style={{width: "366px", height: '367px'}} />
                <SuccessMessage>you have successfully purchased a premium membership.</SuccessMessage>
                <HomeBtn onClick={this.props.handleSubscribe} >Back to home</HomeBtn>
              </SuccessBox>
            }
            {this.deleteCard()}
          </Main1>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {(!this.state.isBilling && !this.state.isPayment && !this.props.isSubscribe) && <div>
          <Heading>Subscription Billing</Heading>
          <Divider />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SubHeading>Manage</SubHeading>
            <div style={{ height: '264px' }}>
              <Grid container spacing={2} >
                {
                  this.CardDetails.map((detail) => {
                    return (
                      <Grid item xs={6}>
                        <Box sx={{ border: "1px solid #E2E8F0", padding: "12px 16px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "24px" }}>
                            <CardTitle>{detail.cardTitle}</CardTitle>
                            <CardBadge isActive={detail.badgeText === 'Active'}>{detail.badgeText}</CardBadge>
                          </div>
                          <CardSubtitle>{detail.cardSubtitle}</CardSubtitle>
                          {
                            detail.cardText.map((text) => (
                              <div style={{ display: 'flex', paddingRight: "10px", alignItems: "center" }}>
                                <CardImage src={detail.badgeText === "Active" ? activeCheckbox : disableCheckbox} alt="disableCheckbox" />
                                <CardText>{text}</CardText>
                              </div>
                            ))
                          }
                          <CardAmount>
                            ${detail.cardAmount}<span style={{ ...CustomFontVariant.palette.font14400, lineHeight: "22px", color: "#64748B" }}>/month</span>
                          </CardAmount>
                          <SubscribeBtn onClick={this.props.handleSubscribe} disabled={detail.badgeText === "Active"}>{detail.badgeText === "Active" ? "Subscribed" : "Subscribe"}</SubscribeBtn>
                        </Box>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </div>
            <Divider />
            <div style={{ gap: "24px" }}>
              <BillingFooter>
                <Label>Billing history</Label>
                <ManageButton data-test-id="billingBtn" onClick={() => this.setState({ isBilling: true })}>Manage</ManageButton>
              </BillingFooter>
              <BillingFooter>
                <Label>Payment method</Label>
                <ManageButton data-test-id="paymentBtn" onClick={() => this.setState({ isPayment: true })}>Manage</ManageButton>
              </BillingFooter>
            </div>
          </div>
        </div>}
        {
          ((this.state.isBilling || this.state.isPayment) && !this.props.isSubscribe) && <>
            <BackBtn>
              <img data-test-id="backBtn" src={backButton} alt="backBtn" onClick={this.handleBackBtn} />
            </BackBtn>
            <BillingContainer>
              <Heading>
                {
                  this.state.isPayment ? "Payment method" : "Billing history"
                }
              </Heading>
              <Divider />
              {
                this.state.isBilling && this.BillingTable()
              }
              {
                this.state.isPayment && this.PaymentMethod()
              }

            </BillingContainer>
          </>
        }
        {
          this.props.isSubscribe && this.SubscribeUI()
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Heading = styled("div")({
  ...CustomFontVariant.palette.font24700,
  lineHeight: "32px"
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "30px 0px",
});

const SubHeading = styled("div")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  marginBottom: "15px",
});

const BillingFooter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  height: "80px",
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  alignItems: "center",
});

const Label = styled(Typography)({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px"
});

const ManageButton = styled("button")({
  width: "184px",
  height: "56px",
  borderRadius: "8px",
  background: "#EAE7DC",
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "none"
});

const CardTitle = styled("p")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
})

const CardBadge = styled("p")<{
  isActive: boolean;
}>(({ isActive }) => ({
  ...CustomFontVariant.palette.font12700,
  lineHeight: "18px",
  width: "60px",
  height: "18px",
  borderRadius: "8px",
  backgroundColor: isActive ? "#FFBB59" : "#EAE7DC",
  display: "flex",
  justifyContent: "center",
}));

const CardImage = styled("img")({
  width: "16px",
  height: "16px",
  paddingRight: "10px"
})

const CardSubtitle = styled("p")({
  ...CustomFontVariant.palette.font14400,
  color: "#64748B",
  lineHeight: "22px",
  margin: "0 auto 13px"
});

const CardText = styled("p")({
  ...CustomFontVariant.palette.font12400,
  lineHeight: "18px",
  margin: "5px 0"
});

const CardAmount = styled("p")({
  width: "364px",
  height: "32px",
  ...CustomFontVariant.palette.font24700,
  lineHeight: "32px",
  display: "flex",
  alignItems: "center",
  margin: "10px 25px 15px"
});

const SubscribeBtn = styled('button')({
  width: "100%",
  height: "44px",
  backgroundColor: "#EAE7DC",
  border: "none",
  borderRadius: "8px",
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  '&:disabled': {
    background: "#E2E8F0",
    color: "#000000"
  }
});

const BackBtn = styled("button")({
  backgroundColor: "#EAE7DC",
  width: "36px",
  height: "36px",
  border: "none",
  borderRadius: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const BillingContainer = styled("div")({
  padding: "10px",
  marginTop: "30px"
})

const Row = styled(TableCell)({
  ...CustomFontVariant.palette.font12400,
  lineHeight: "18px",
});

const PaymentMethodTitle = styled("p")({
  ...CustomFontVariant.palette.font20700,
  lineHeight: "28px",
  color: "#64748B"
});

const CardBox = styled("div")({
  height: "82px",
  border: "1px solid #94A3B8",
  borderRadius: "8px",
  backgroundColor: "#EAE7DC",
  alignContent: "center",
  marginBottom: "10px"
});

const NoCardBox = styled("div")({
  height: "96px",
  border: "1px solid #D1FAE5",
  borderRadius: "8px",
  backgroundColor: "white",
  justifyContent: "center",
  display: "flex"
});

const CustomRadio = styled(Radio)({
  '&:hover': {
    backgroundColor: "#EAE7DC",
  }
});

const BpIcon = styled('span')({
  borderRadius: '16px',
  width: 20,
  height: 20,
  boxShadow: 'none',
  backgroundColor: 'white',
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FFBB59',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  }
});

const CardNumber = styled("p")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  margin: 0,

});

const CardAction = styled('p')({
  ...CustomFontVariant.palette.font12400,
  lineHeight: "22px",
  margin: 0,
  color: "#64748B",
  cursor: "pointer"
});

const CardContent = styled('div')({
  display: 'flex',
  gap: "8px",
  alignItems: "center"
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperWidthSm': {
    width: "600px",
    height: "200px",
    padding: "40px",
    borderRadius: "25px"
  },
  '& .MuiDialogContent-root': {
    padding: "30px 0px 30px !important",
    flex: "0 1 auto",
    '& .MuiTypography-body1': {
      ...CustomFontVariant.palette.font20400,
      color: "#334155"
    }
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    padding: "0px",
    '& .MuiTypography-h6': {
      ...CustomFontVariant.palette.font20700,
    }
  }
}));

const CancelButton = styled('button')({
  width: '201px',
  height: "56px",
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  textTransform: "none",
  backgroundColor: "white",
  border: "1px solid #000000",
  borderRadius: "5px",
  '&: hover': {
    background: "white"
  }
});

const ConfirmButton = styled('button')({
  width: '201px',
  height: "56px",
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  textTransform: "none",
  backgroundColor: customTheme.palette.grey.primary,
  borderRadius: "5px",
  border: "none",
  '&:hover': {
    background: customTheme.palette.grey.primary
  }
});

const AddButton = styled('button')({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  color: "#FFBB59",
  border: "none",
  background: "none"
});

const FormLabel = styled("p")({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  margin: "0"
})

const CardFormField = {
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 0px",
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #CBD5E1',
      height: "56px",
      borderRadius: "8px"
    },
    '&:hover fieldset': {
      border: '1px solid #CBD5E1',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #CBD5E1',
    },
  },
};

const AddCardBtn = styled('button')({
  width: "156px",
  height: "44px",
  borderRadius: "8px",
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  background: "white",
  border: "1px solid black"
});

const Main1 = styled("div")({
  padding: "40px 36px",
});

const MainContainer1 = styled("div")({
  borderRadius: 24,
  padding: "10px 40px 60px",
  boxShadow: `
      0px 8px 32px 0px #0000000F,
      0px 4px 8px 0px #00000008,
      0px 25px 50px 0px #00000017
    `,
});

const SubscriptionDetails = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const DetailLabel = styled('p')({
  ...CustomFontVariant.palette.font16400,
  lineHeight: "24px",
});

const DetailValue = styled('p')({
  ...CustomFontVariant.palette.font12700,
  lineHeight: "18px",
});

const TotalAmount = styled('p')({
  ...CustomFontVariant.palette.font20700,
  lineHeight: "28px",
  color: "#FFBB59",
});

const PayNowBtn = styled('button')({
  width: "188px",
  height: "56px",
  border: "none",
  borderRadius: "8px",
  background: "#EAE7DC",
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
});

const SuccessBox = styled('div')({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: 'center',
  marginTop: '60px'
})

const HomeBtn = styled('button')({
  width: '230px',
  height: "56px",
  border: "none",
  borderRadius: "8px",
  background: "#EAE7DC",
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
});

const SuccessMessage = styled('p')({
  ...CustomFontVariant.palette.font20700,
  lineHeight: "28px",
})
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  checkedActionBar: boolean;
  checkedActionBarText: string;
  notificationData: any;
  token: string;
  pushNotifications: boolean;
  emailNotifications: boolean;
  inAppNotifications: boolean;
  loading: boolean;
  notificationId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NotificationsettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  notificationAPICallId: any;
  setNotificationAPICall: any;
  setNotificationGroupAPICall: any;
  setNotificationSubGroupAPICall: any;
  getNotificationStatusCallId: any;
  updateNotificationStatusCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      checkedActionBar: false,
      checkedActionBarText: configJSON.notificationGroup1,
      notificationData: [],
      token: "",
      pushNotifications: false,
      emailNotifications: false,
      inAppNotifications: false,
      loading: true,
      notificationId: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getNotificationStatus();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getNotificationDataRequest(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson.data) {
        if (apiRequestCallId === this.notificationAPICallId) {
          this.setState({ notificationData: responseJson.data });
        }
        if (apiRequestCallId === this.setNotificationAPICall) {
          var array = this.state.notificationData;
          for (let i = 0; i < array.length; i++) {
            if (array[i].id === responseJson.data.id) {
              array[i] = responseJson.data;
            }
          }
          this.setState({ notificationData: array });
        }
        if (apiRequestCallId === this.setNotificationGroupAPICall) {
          var array = this.state.notificationData;
          for (let i = 0; i < array.length; i++) {
            for (
              let j = 0;
              j < array[i].attributes.notification_groups.data.length;
              j++
            ) {
              if (
                array[i].attributes.notification_groups.data[j].id ===
                responseJson.data.id
              ) {
                array[i].attributes.notification_groups.data[j] =
                  responseJson.data;
              }
            }
          }
          this.setState({ notificationData: array });
        }
        if (apiRequestCallId === this.setNotificationSubGroupAPICall) {
          var array = this.state.notificationData;
          for (let i = 0; i < array.length; i++) {
            for (
              let j = 0;
              j < array[i].attributes.notification_groups.data.length;
              j++
            ) {
              for (
                let k = 0;
                k <
                array[i].attributes.notification_groups.data[j].attributes
                  .notification_subgroups.data.length;
                k++
              ) {
                if (
                  array[i].attributes.notification_groups.data[j].attributes
                    .notification_subgroups.data[k].id === responseJson.data.id
                ) {
                  array[i].attributes.notification_groups.data[
                    j
                  ].attributes.notification_subgroups.data[k] =
                    responseJson.data;
                }
              }
            }
          }
          this.setState({ notificationData: array });
        }
        runEngine.debugLog("API Recived", responseJson.data);
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ notificationData: this.state.notificationData });
        this.parseApiCatchErrorResponse(errorReponse);
      }

      switch (apiRequestCallId) {
        case this.getNotificationStatusCallId:
          this.getNotificationStatusCallIdApi(responseJson);
          break;

        case this.updateNotificationStatusCallId:
          this.updateNotificationStatusCallIdApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getNotificationDataRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setMainCheckBox(item: any) {
    var data = {
      title: item.attributes.title,
      description: item.attributes.description,
      state: item.attributes.state === "active" ? "inactive" : "active",
    };
    this.postNotificationDataRequest(
      configJSON.updateNotificationAPIEndPoint + `${item.id}`,
      data,
      "setNotification"
    );
  }

  setGroupCheckBox(index: number, groupIndex: number, groupitem: any) {
    var data = {
      group_type: groupitem.attributes.group_type,
      group_name: groupitem.attributes.group_name,
      state: groupitem.attributes.state === "active" ? "inactive" : "active",
      notification_setting_id: groupitem.attributes.notification_setting_id,
    };
    this.postNotificationDataRequest(
      configJSON.updateNotificationGroupAPIEndPoint + `${groupitem.id}`,
      data,
      "setGroupNotification"
    );
  }

  setSubGroupCheckBox = (subGroupItem: any) => {
    let data = {
      subgroup_type: subGroupItem.attributes.subgroup_type,
      subgroup_name: subGroupItem.attributes.subgroup_name,
      state: subGroupItem.attributes.state === "active" ? "inactive" : "active",
      notification_group_id: subGroupItem.attributes.notification_group_id,
    };

    this.postNotificationDataRequest(
      configJSON.updateNotificationSubGroupAPIEndPoint + `${subGroupItem.id}`,
      data,
      "setSubGroupNotification"
    );
  };

  postNotificationDataRequest = (
    endPointURL: string,
    data: any,
    type: string
  ): boolean => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (type === "setNotification") {
      this.setNotificationAPICall = requestMessage.messageId;
    } else if (type === "setGroupNotification") {
      this.setNotificationGroupAPICall = requestMessage.messageId;
    } else if (type === "setSubGroupNotification") {
      this.setNotificationSubGroupAPICall = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getNotificationStatusCallIdApi = (responseJson: any) => {
    if (responseJson.data) {
      const {
        push_notification,
        email_notification,
        inapp_notification,
      } = responseJson.data.attributes;
      const notificationId = responseJson.data.id;
      this.setState({
        notificationId,
        pushNotifications: push_notification,
        emailNotifications: email_notification,
        inAppNotifications: inapp_notification,
        loading: false,
      });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  updateNotificationStatusCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.getNotificationStatus();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  handleNavigation = async (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleToggle = (key: keyof S) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }),
      () => {
        this.updateNotificationStatus();
      }
    );
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  getNotificationStatus = async () => {
    this.getNotificationStatusCallId = await this.apiCall({
      method: "GET",
      endPoint:
        "bx_block_notifsettings/notification_settings/get_notification_setting",
      contentType: "application/json",
    });
  };

  updateNotificationStatus = async () => {
    const {
      pushNotifications,
      emailNotifications,
      inAppNotifications,
    } = this.state;
    this.updateNotificationStatusCallId = await this.apiCall({
      method: "PATCH",
      endPoint: `bx_block_notifsettings/notification_settings/${this.state.notificationId}`,
      contentType: "application/json",
      body: {
        title: "Notification Settings",
        description: "default notification setting",
        state: "active",
        push_notification: pushNotifications,
        email_notification: emailNotifications,
        inapp_notification: inAppNotifications,
      },
    });
  };
  // Customizable Area End
}

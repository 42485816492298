import React from "react";

// Customizable Area Start
// Customizable Area End

import PushnotificationsController, {
  Props,
} from "./PushnotificationsController";

export default class Pushnotifications extends PushnotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>notification</div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End

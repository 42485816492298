import React from "react";

// Customizable Area Start
import { Grid, styled } from "@material-ui/core";
import Header from "../../../blocks/landingpage/src/Header.web";
import { aboutUs } from "./assets";
import Footer from "../../../blocks/landingpage/src/Footer.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Header
          activeTab="2"
          navigation={this.props.navigation}
          id={""}
          isAuthenticated={Boolean(this.state.isLoggedIn)}
        />
        <MissionContainer>
          <Grid container spacing={8}>
            <Grid item xs={12} md={4} style={{display: "flex", justifyContent: 'center', alignItems: "center"}}>
              <Heading>Our mission</Heading>
            </Grid>
            <Grid item xs={12} md={7} >
              <SubHeading>
              Lorem ipsum dolor sit amet consectetur. Adipiscing donec risus tincidunt suspendisse nec vitae scelerisque eget integer. Ut pulvinar arcu arcu orci viverra est lobortis nulla mauris. Euismod phasellus sit volutpat id ante in nibh. In nec eget enim at id fermentum. Amet enim enim adipiscing faucibus eros consectetur amet augue lectus. Et malesuada in vel est quam neque. Risus tempus faucibus imperdiet lectus vel
              </SubHeading>
            </Grid>
          </Grid>
        </MissionContainer>
        <AboutUsContent>
          <Grid container spacing={8} style={{display: "flex", justifyContent: 'space-between'}}>
            <Grid item xs={12} md={5}>
              <Heading>
                What we do?
              </Heading>
              <SubHeading style={webStyle.mt10}>
                Lorem ipsum dolor sit amet consectetur. Aliquam parturient id nulla vestibulum cursus morbi congue est nullam. Ullamcorper aliquam nisl dolor interdum semper libero nulla. Auctor aliquet dui porttitor vel aliquet tellus ipsum neque aliquam. Pellentesque dictum consectetur quis odio ac nunc consequat. Massa ipsum nunc suspendisse tincidunt a dolor egestas quis. Dictumst duis dictum ut est. Habitant ipsum in aliquam ornare felis aliquam eu elementum. Sit sed eget posuere risus felis.
              </SubHeading>
            </Grid>
            <Grid item xs={12} md={5} style={{padding: "0px !important"}}>
              <Img
                src={aboutUs}
                alt="image"
              />
            </Grid>
          </Grid>
          <DividerBlock>
            <Divider />
            <Divider />
          </DividerBlock>
        </AboutUsContent>
        <Footer navigation={this.props.navigation} id={""} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Divider = styled("div")({
  borderBottom: `1px solid #E2E8F0`,
  width: "50%"
});

const DividerBlock = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "70px"
})

const webStyle = {
  mt10: {
    marginTop: 10,
  },
};

const MissionContainer = styled("div")({
  padding: "70px 100px",
  backgroundColor: customTheme.palette.grey.primary,
  display: "flex", 
  alignItems: "center"
});

const AboutUsContent = styled("div")({
  padding: "70px 100px",
  display: 'flex',
  flexDirection: "column",
  gap: "75px"
});

const Heading = styled("div")({
  fontSize: 36,
  fontWeight: 700,
  fontFamily: "Open Sans",
  lineHeight: "44px",
});

const SubHeading = styled("div")({
  ...CustomFontVariant.palette.font24400,
  lineHeight: "32px",
});

const Img = styled("img")({
  width: "100%",
  borderRadius: 24,
  height: 346,
});
// Customizable Area End

import React from "react";

// Customizable Area Start
import { styled, Avatar } from "@mui/material";
import Header from "../../../blocks/landingpage/src/Header.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomMenu from "../../../components/src/CustomMenuItem.web";
import { action, check, viewCircle } from "./assets";
import Toast from "../../../components/src/CustomSnackbar.web";
import { CircularProgress } from "@material-ui/core";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderContent() {
    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: "center", marginTop: "40px" }}>
          <CircularProgress
            size={20}
            color={customTheme.palette.grey.primary}
          />
        </div>
      );
    }

    if (this.state.noDataMsg) {
      return <DataNotFoundN>{this.state.noDataMsg}</DataNotFoundN>;
    }

    return (
      <>
        <div
          style={{
            ...webStyle.mark,
            opacity: this.state.isDisabled ? 0.5 : 1,
            pointerEvents: this.state.isDisabled ? "none" : "auto",
          }}
          onClick={this.state.isDisabled ? undefined : this.markAllAsRead}
          data-test-id="markAllAsRead"
        >
          <img src={check} alt="icon" />
          <Heading>Mark all as read</Heading>
        </div>

        <Divider />
        {this.state.allNotifications.map((item: any, index: any) => (
          <div key={index}>
            <MainNotification key={item.id}>
              <div style={webStyle.mainDiv}>
                <Avatar
                  src={item.attributes.profile_image}
                  style={{ width: 42, height: 42, cursor: "pointer" }}
                  onClick={() => this.handleNavigation(item.attributes.app_url)}
                />
                <div style={{ width: "100%" }}>
                  <div style={webStyle.title}>
                    <div
                      style={webStyle.head}
                      onClick={() =>
                        this.handleNavigation(item.attributes.app_url)
                      }
                    >
                      <Heading>{item.attributes.headings}</Heading>
                      {!item.attributes.is_read && (
                        <img
                          src={viewCircle}
                          alt="icon"
                          style={{ width: 10, height: 10 }}
                        />
                      )}
                    </div>
                    <div>
                      <img
                        src={action}
                        alt="icon"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.handleOpen(e, item.id)}
                        data-test-id="handleOpen"
                      />
                      <CustomMenu
                        open={this.state.open}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        options={this.menuOptions()}
                        position="right"
                        data-test-id="handleClose"
                      />
                    </div>
                  </div>
                  <div style={webStyle.description}>
                    <SubHeading
                      onClick={() =>
                        this.handleNavigation(item.attributes.app_url)
                      }
                    >
                      {item.attributes.contents}
                    </SubHeading>
                    <Time>{this.timeSince(item.attributes.created_at)}</Time>
                  </div>
                </div>
              </div>
            </MainNotification>
            <DividerMain />
          </div>
        ))}
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Header
          navigation={this.props.navigation}
          id={""}
          markAllAsRead={this.markAllAsRead}
          timeSince={this.timeSince}
          loadingNotifications={this.state.isLoading}
          isDisabled={this.state.isDisabled}
        />
        <div style={{ padding: "32px 40px" }}>
          <Head>Notification Details</Head>
          {this.renderContent()}
        </div>
        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          data-test-id="handleToastClose"
          onClose={this.handleToastClose}
          duration={3000}
          position={{ vertical: "top", horizontal: "center" }}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  head: {
    display: "flex",
    gap: 6,
    alignItems: "center",
    cursor: "pointer",
  },
  mark: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "end",
    cursor: "pointer",
  },
  mainDiv: {
    display: "flex",
    gap: 24,
    alignItems: "center",
    width: "100%",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "6px",
  },
  description: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

const Head = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font30700,
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "16px 0px 40px 0px",
});

const DividerMain = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "24px 0px",
});

const MainNotification = styled("div")({
  ...CustomFontVariant.palette.font18700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 16,
  width: "100%",
});

const Heading = styled("div")({
  ...CustomFontVariant.palette.font14700,
});

const SubHeading = styled("div")({
  ...CustomFontVariant.palette.font14400,
  marginTop: 4,
  maxWidth: 300,
  lineHeight: "22px",
  color: customTheme.palette.grey.bold,
  cursor: "pointer",
});

const Time = styled("div")({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.grey.superLight,
});

const DataNotFoundN = styled("div")({
  margin: 40,
  display: "flex",
  justifyContent: "center",
  ...CustomFontVariant.palette.font14700,
});
// Customizable Area End

import {
  Box,
  Button,
  Typography,
  styled,
  Drawer,
  IconButton,
  useMediaQuery,
  Avatar,
  Popover,
  withStyles,
  Badge,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import customTheme from "./CustomTheme.web";
import CustomFontVariant from "./CustomFontVariant.web";
import {
  Community,
  AppLogo,
  Home,
  About,
  notification,
  setting,
  network,
  message,
  profileMobileView,
  viewCircle,
  check,
  notificationOpen,
} from "./assets.web";

interface HeaderProps {
  navigation: (path: string) => void;
  activeMenu: string;
  drawerOpen: boolean;
  handleMenuClick: (id: string) => void;
  toggleDrawer: () => void;
  isAuthenticated?: boolean;
  profile?: any;
  anchorEl?: any;
  popoverOpen?: any;
  handleOpenPopover?: (event: React.MouseEvent<HTMLElement>) => void;
  handleClosePopover?: () => void;
  navigateNotification?: (path: string) => void;
  notificationData?: any;
  notificationCount?: any;
  markAllAsRead?: () => void;
  loadingNotifications?: boolean;
  isDisabled?: boolean;
  timeSince?: (time: any) => any;
}

const Header: React.FC<HeaderProps> = ({
  navigation,
  activeMenu,
  drawerOpen,
  handleMenuClick,
  toggleDrawer,
  isAuthenticated,
  profile,
  anchorEl,
  popoverOpen,
  handleOpenPopover,
  handleClosePopover,
  navigateNotification,
  notificationData,
  notificationCount,
  markAllAsRead,
  loadingNotifications,
  isDisabled,
  timeSince,
}) => {
  const isMobile = useMediaQuery("(max-width:900px)");

  const StyledBadge = withStyles(() => ({
    badge: {
      backgroundColor: customTheme.palette.red.primary,
      color: customTheme.palette.white.main,
      fontSize: "0.75rem",
      minWidth: 20,
      height: 20,
      borderRadius: "50%",
    },
  }))(Badge);

  const Menu = isAuthenticated
    ? [
        {
          id: "1",
          name: "Home",
          icon: Home,
          path: "LandingPage",
        },
        {
          id: "2",
          name: "About Us",
          icon: About,
          path: "NavigationMenu",
        },
        {
          id: "3",
          name: "Network",
          icon: network,
          path: "FriendsList",
        },
        {
          id: "4",
          name: "Messages",
          icon: message,
          path: "Chat",
        },
        {
          id: "5",
          name: "Community Feed",
          icon: Community,
          path: "NewsFeed",
        },
        isMobile && {
          id: "6",
          name: "Notifications",
          icon: notification,
          path: "Notifications",
        },
        isMobile && {
          id: "7",
          name: "Settings",
          icon: setting,
          path: "Settings",
        },
        isMobile && {
          id: "8",
          name: "Profile",
          icon: profileMobileView,
          path: "Customisableuserprofiles",
        },
      ]
    : [
        {
          id: "1",
          name: "Home",
          icon: Home,
          path: "LandingPage",
        },
        {
          id: "2",
          name: "About Us",
          icon: About,
          path: "NavigationMenu",
        },
        {
          id: "3",
          name: "Community Feed",
          icon: Community,
          path: "NewsFeed",
        },
      ];

  return (
    <Main>
      <LeftContainer>
        {isMobile && (
          <IconButton onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        )}
        <img src={AppLogo} alt="logo" />
      </LeftContainer>
      {!isMobile && (
        <>
          <Menus>
            {Menu.map((item: any) => (
              <MenuItem
                key={item.id}
                onClick={() => handleMenuClick(item.id)}
                data-test-id="handleMenuClick"
              >
                {activeMenu === item.id && (
                  <img
                    style={{ marginRight: "4px", height: 14, width: 15 }}
                    src={item.icon}
                    alt="icon"
                  />
                )}
                <Typography
                  style={{
                    fontWeight: activeMenu === item.id ? 700 : 400,
                    fontSize: "16px",
                    color:
                      activeMenu === item.id
                        ? customTheme.palette.black.primary
                        : customTheme.palette.grey.secondary,
                    fontFamily: "Open Sans",
                  }}
                  onClick={() => navigation(item.path)}
                >
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
          </Menus>
          {isAuthenticated ? (
            <LoogedInSection>
              <ButtonAuth
                style={{
                  backgroundColor: customTheme.palette.grey.primary,
                  width: 105,
                }}
                variant="contained"
                onClick={() => navigation("ContentModeration")}
              >
                Moderate
              </ButtonAuth>
              <div>
                <StyledBadge
                  badgeContent={notificationCount || 0}
                  invisible={popoverOpen || !notificationCount}
                >
                  <img
                    src={popoverOpen ? notificationOpen : notification}
                    alt="icon"
                    style={{ cursor: "pointer", width: 24, height: 24 }}
                    onClick={handleOpenPopover}
                  />
                </StyledBadge>
                <PopoverComp
                  open={popoverOpen}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div style={{ paddingBottom: 20 }}>
                    <Label>Notifications</Label>
                    <Divider />
                    {loadingNotifications ? (
                      <div style={{ textAlign: "center", margin: "40px 0" }}>
                        <CircularProgress
                          size={20}
                          color={customTheme.palette.grey.primary}
                        />
                      </div>
                    ) : (
                      <>
                        {!notificationData.message && (
                          <div
                            style={{
                              ...webStyle.mark,
                              opacity: isDisabled ? 0.5 : 1,
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                            onClick={isDisabled ? undefined : markAllAsRead}
                            data-test-id="markAllAsRead"
                          >
                            <img src={check} alt="icon" />
                            <Heading>Mark all as read</Heading>
                          </div>
                        )}
                        {notificationData.message !==
                          "No notification found." &&
                        Array.isArray(notificationData.data) ? (
                          notificationData.data.slice(0, 3).map((item: any) => (
                            <MainNotification
                              key={item.id}
                              onClick={() =>
                                navigateNotification &&
                                navigateNotification(item.attributes.app_url)
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: 12,
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  src={item.attributes.profile_image}
                                  style={{ width: 42, height: 42 }}
                                />
                                <div>
                                  <div style={webStyle.head}>
                                    <Heading>
                                      {item.attributes.headings}
                                    </Heading>
                                    {!item.attributes.is_read && (
                                      <img
                                        src={viewCircle}
                                        alt="icon"
                                        style={{ width: 10, height: 10 }}
                                      />
                                    )}
                                  </div>
                                  <SubHeading>
                                    {item.attributes.contents}
                                  </SubHeading>
                                </div>
                              </div>
                              <Time>
                                {timeSince &&
                                  timeSince(item.attributes.created_at)}
                              </Time>
                            </MainNotification>
                          ))
                        ) : (
                          <DataNotFoundN>
                            {notificationData.message}
                          </DataNotFoundN>
                        )}
                      </>
                    )}
                    <Divider />
                    <Button
                      onClick={() =>
                        navigateNotification &&
                        navigateNotification("NotificationsWeb")
                      }
                      style={webStyle.Button}
                    >
                      View All
                    </Button>
                  </div>
                </PopoverComp>
              </div>
              <img
                style={{ cursor: "pointer" }}
                src={setting}
                alt="setting"
                onClick={() => navigation("Settings")}
              />
              <Avatar
                style={{ cursor: "pointer" }}
                src={profile}
                onClick={() => navigation("Customisableuserprofiles")}
              />
            </LoogedInSection>
          ) : (
            <Buttons>
              <ButtonAuth
                variant="outlined"
                onClick={() => navigation("EmailAccountRegistrationWeb")}
              >
                Sign Up
              </ButtonAuth>
              <ButtonAuth
                style={{
                  backgroundColor: customTheme.palette.grey.primary,
                  width: 105,
                }}
                variant="contained"
                onClick={() => navigation("EmailAccountLoginBlock")}
              >
                Login
              </ButtonAuth>
            </Buttons>
          )}
        </>
      )}
      {isMobile && (
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
          <DrawerContent>
            {Menu.map((item: any) => (
              <MenuItem key={item.id} onClick={() => handleMenuClick(item.id)}>
                <img
                  style={{ marginRight: "4px" }}
                  src={item.icon}
                  alt="icon"
                />
                <Typography
                  style={{
                    fontWeight: activeMenu === item.id ? 700 : 400,
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    color:
                      activeMenu === item.id
                        ? customTheme.palette.black.primary
                        : customTheme.palette.grey.secondary,
                  }}
                  onClick={() => navigation(item.path)}
                >
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
            {!isAuthenticated ? (
              <ButtonContainer>
                <ButtonAuth
                  variant="outlined"
                  onClick={() => navigation("EmailAccountRegistrationWeb")}
                >
                  Sign Up
                </ButtonAuth>
                <ButtonAuth
                  style={{
                    backgroundColor: customTheme.palette.grey.primary,
                    minWidth: 105,
                  }}
                  variant="contained"
                  onClick={() => navigation("EmailAccountLoginBlock")}
                >
                  Login
                </ButtonAuth>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <ButtonAuth variant="outlined">Moderate</ButtonAuth>
              </ButtonContainer>
            )}
          </DrawerContent>
        </Drawer>
      )}
    </Main>
  );
};

const Main = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: customTheme.palette.white.main,
  height: "76px",
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  alignItems: "center",
  padding: "0px 40px",
  "@media (max-width:900px)": {
    padding: "0px 16px",
  },
});

const LeftContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

const Menus = styled(Box)({
  display: "flex",
  gap: "24px",
});

const MenuItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: "4px",
});

const Buttons = styled(Box)({
  display: "flex",
  gap: "24px",
});

const DrawerContent = styled(Box)({
  width: "250px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  marginTop: "20px",
});

const ButtonAuth = styled(Button)({
  fontSize: 16,
  fontWeight: 700,
  fontFamily: "Open Sans",
  textTransform: "none",
});

const LoogedInSection = styled("div")({
  display: "flex",
  gap: 24,
  alignItems: "center",
});

const webStyle = {
  head: {
    display: "flex",
    gap: 6,
    alignItems: "center",
  },
  mark: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "end",
    cursor: "pointer",
    paddingRight:20,
  },
  Button: {
    width: "100% ",
    ...CustomFontVariant.palette.font14700,
    textTransform: "none",
  },
};

const Label = styled("div")({
  ...CustomFontVariant.palette.font18700,
  padding:"20px 0px 0px 20px"
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "24px 0px 16px 0px",
});

const MainNotification = styled("div")({
  ...CustomFontVariant.palette.font18700,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 16,
  cursor: "pointer",
  padding:"0px 20px"
});

const Heading = styled("div")({
  ...CustomFontVariant.palette.font14700,
});

const SubHeading = styled("div")({
  ...CustomFontVariant.palette.font14400,
  marginTop: 4,
  maxWidth: 300,
  lineHeight: "22px",
  color: customTheme.palette.grey.bold,
});

const Time = styled("div")({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.grey.superLight,
});

const PopoverComp = styled(Popover)({
  "& .MuiPopover-paper": {
    top: "78px!important",
    width: 448,
    left: "unset!important",
    right: "110px",
    borderRadius: 8,
    minWidth: "fit-content",
    maxHeight: 500,
  },
});

const DataNotFoundN = styled("div")({
  margin: 40,
  display: "flex",
  justifyContent: "center",
  ...CustomFontVariant.palette.font14700,
});

export default Header;

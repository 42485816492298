import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import firebase from "../../../web/firebase";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  activeTab?: string;
  isAuthenticated?:boolean;
  markAllAsRead?: () => void;
  timeSince?: (time: any) => any;
  loadingNotifications?: boolean;
  isDisabled?: boolean;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  drawerOpen: boolean;
  profile: any;
  activeMenu: string;
  anchorEl: any;
  popoverOpen: boolean;
  allNotifications: any;
  notificationId: any;
  notificationCount: any;
  toastOpen: boolean;
  toastMessage: string;
  toastDescription: string;
  loadingNotifications: boolean;
  isDisabled: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HeaderWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getNotificationCallId: string = "";
  getCountCallId: string = "";
  updateCountCallId: string = "";
  markAllAsReadCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      drawerOpen: false,
      profile: "",
      activeMenu: "",
      anchorEl: null,
      popoverOpen: false,
      allNotifications: [],
      notificationId: null,
      notificationCount: null,
      toastOpen: false,
      toastMessage: "",
      toastDescription: "",
      loadingNotifications: true,
      isDisabled: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getNotificationCallId:
          this.getNotificationCallIdApi(responseJson);
          break;

        case this.getCountCallId:
          this.getCountCallIdApi(responseJson);
          break;

        case this.updateCountCallId:
          this.updateCountCallIdApi(responseJson);
          break;

          case this.markAllAsReadCallId:
            this.markAllAsReadCallIdApi(responseJson);
            break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      activeMenu: this.props.activeTab || "",
    });
    this.redirectInvalidAccount();
    this.getNotification();
    this.getCount();
    const messaging = firebase.messaging();
    this.listenForMessages(messaging);
  }

  listenForMessages(messaging: any) {
    messaging.onMessage(({ notification }: any) => {
      const { title, body } = notification || {};
      if (Notification.permission === "granted") {
        setTimeout(() => {
          this.getCount();
          this.setState({
            toastOpen: true,
            toastMessage: title,
            toastDescription: body,
          });
        }, 1000);
      }
    });
  }

  handleToastClose = () => this.setState({ toastOpen: false });

  getNotificationCallIdApi = (responseJson: any) => {
    if (responseJson) {
      const isDisabled =
        responseJson?.data &&
        responseJson.data.every(
          (item: any) => item.attributes?.is_read === true
        );
      this.setState({
        allNotifications: responseJson,
        loadingNotifications: false,
        isDisabled,
      });
    }
  };

  getCountCallIdApi = (responseJson: any) => {
    if (responseJson.count) {
      this.setState({ notificationCount: responseJson.count });
    }
  };

  updateCountCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({ notificationCount: null });
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleLogInNavigation("EmailAccountLoginBlock");
    }
  };

  redirectInvalidAccount = async () => {
    const authToken = await getStorageData("token");
    const profile = await getStorageData("profile");
    this.setState({ profile });
    const allowedLoginRoutes = [
      "/Customisableuserprofiles",
      "/FriendsProfile",
      "/FriendsList",
      "/Settings",
      "/search",
      "/Cfargooglestreetviewinlaycontent",
    ];
    const currentPath = window.location.pathname;
    if (!authToken && allowedLoginRoutes.includes(currentPath)) {
      this.handleLogInNavigation("EmailAccountLoginBlock");
    }
  };

  handleMenuClick = (id: any) => {
    this.setState({ activeMenu: id });
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleLogInNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleNavigationNotification = (path: any) => {
    this.handleLogInNavigation(path);
    this.setState({ anchorEl: null, popoverOpen: false });
  };

  handleOpenPopover = async (event: React.MouseEvent<HTMLElement>) => {
    this.setState(
      {
        anchorEl: event.currentTarget,
        popoverOpen: true,
        loadingNotifications: true,
      },
      () => {
        this.getNotification();
        this.updateCount();
      }
    );
  };

  handleClosePopover = () => {
    this.setState({
      popoverOpen: false,
    });
  };

  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, token };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  getNotification = async () => {
    this.getNotificationCallId = await this.apiCall({
      method: "GET",
      endPoint: "bx_block_notifications/notifications",
      contentType: "application/json",
    });
  };

  getCount = async () => {
    this.getCountCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_notifications/notifications/notification_count`,
      contentType: "application/json",
    });
  };

  updateCount = async () => {
    this.updateCountCallId = await this.apiCall({
      method: "PATCH",
      endPoint: `bx_block_notifications/notifications/update_count`,
      contentType: "application/json",
    });
  };

  markAllAsRead = async () => {
    this.markAllAsReadCallId = await this.apiCall({
      method: "PUT",
      endPoint: `bx_block_notifications/notifications/read_all`,
      contentType: "application/json",
    });
  };

  markAllAsReadCallIdApi = (responseJson: any) => {
    if (responseJson.message) {
      this.getNotification();
    } else if (responseJson.errors && responseJson.errors[0]?.token) {
      this.handleNavigation("EmailAccountLoginBlock");
    }
  };

  handleNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  // Customizable Area End
}

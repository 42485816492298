import React from "react";

// Customizable Area Start
import { Typography, styled, Box, Button } from "@material-ui/core";
import Header from "../../../components/src/CustomHeader.web";
import { background, logoMain } from "./assets";
import customTheme from "../../../components/src/CustomTheme.web";
import customFontVariant from "../../../components/src/CustomFontVariant.web";
// Customizable Area End

import ForgotPasswordWebController, {
  Props,
} from "./ForgotPasswordWebController";

export default class ForgotPassword extends ForgotPasswordWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isSendResetLink ? (
          <Wrapper2>
            <Header
              navigation={this.handleNavigation}
              activeMenu={this.state.activeMenu}
              drawerOpen={this.state.drawerOpen}
              toggleDrawer={this.toggleDrawer}
              handleMenuClick={this.handleMenuClick}
            />
            <BackGround2 />
            <MainContainer2>
              <Main2>
                <div style={{ textAlign: "center" }}>
                  <img src={logoMain} alt="logoMain" />
                </div>
                <TypoTextItem1>Password recovery</TypoTextItem1>
                <EmailMain1>
                  <ForgotButton>Check your email</ForgotButton>
                  <TypoDesc>{this.state.emailLinkMsg}</TypoDesc>
                </EmailMain1>
                <TypographyoLoginMain1>
                  <Span onClick={this.forgotPasswordHandler}>
                    Resend e-mail
                  </Span>
                  <TypographyoLoginMain2>
                    <TypographyoLogin>Back to</TypographyoLogin>
                    <Span
                      onClick={() =>
                        this.handleNavigation("EmailAccountLoginBlock")
                      }
                      data-test-id="handleLogInNavigation"
                    >
                      Log In
                    </Span>
                  </TypographyoLoginMain2>
                </TypographyoLoginMain1>
              </Main2>
            </MainContainer2>
          </Wrapper2>
        ) : (
          <Wrapper2>
            <Header
              navigation={this.handleNavigation}
              activeMenu={this.state.activeMenu}
              drawerOpen={this.state.drawerOpen}
              toggleDrawer={this.toggleDrawer}
              handleMenuClick={this.handleMenuClick}
            />
            <BackGround2 />
            <MainContainer2>
              <Main2>
                <div style={{ textAlign: "center" }}>
                  <img src={logoMain} alt="logoMain" />
                </div>
                <TypoTextItem1>Password recovery</TypoTextItem1>
                {this.state.emailErrorMain && (
                  <TermError>
                    <ErrorTypo>{this.state.emailErrorMain}</ErrorTypo>
                  </TermError>
                )}
                <EmailMain1>
                  <ForgotButton>Forgot Password?</ForgotButton>
                  <TypoDesc>
                    No worries, we'll send a recovery link to your email.
                  </TypoDesc>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    placeholder="Your email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    data-test-id="handleEmailChange"
                  />
                  {this.state.emailError && (
                    <ErrorMsg>{this.state.emailError}</ErrorMsg>
                  )}
                </EmailMain1>
                <CreateButton
                  variant="contained"
                  size="large"
                  onClick={this.forgotPasswordHandler}
                  data-test-id="forgotPasswordHandler"
                >
                  Send a recovery link
                </CreateButton>
                <TypographyoLoginMain>
                  <TypographyoLogin>Back to</TypographyoLogin>
                  <Span
                    onClick={() =>
                      this.handleNavigation("EmailAccountLoginBlock")
                    }
                    data-test-id="handleLogInNavigation"
                  >
                    Log In
                  </Span>
                </TypographyoLoginMain>
              </Main2>
            </MainContainer2>
          </Wrapper2>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const Wrapper2 = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const BackGround2 = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
  backgroundPosition: "center",
  zIndex: -1,
});

const Main2 = styled(Box)({
  padding: 40,
  backgroundColor: customTheme.palette.white.main,
  marginTop: "100px",
  marginBottom: "100px",
  maxWidth: "440px",
  borderRadius: 24,
  width: "100%",
});

const MainContainer2 = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const TypoTextItem1 = styled(Typography)({
  ...customFontVariant.palette.font24700,
  marginTop: 40,
});

const EmailMain1 = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: 40,
});

const Label = styled("label")({
  ...customFontVariant.palette.font14700,
  color: customTheme.palette.grey.secondary,
  marginBottom: 5,
  marginTop: 32,
});

const Input = styled("input")(({ isError }: any) => ({
  height: 56,
  borderRadius: 8,
  padding: 10,
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const Span = styled("span")({
  color: customTheme.palette.black.primary,
  ...customFontVariant.palette.font16700,
  cursor: "pointer",
});

const TypographyoLogin = styled(Typography)({
  color: customTheme.palette.grey.secondary,
  ...customFontVariant.palette.font16400,
});

const TypographyoLoginMain = styled("div")({
  display: "flex",
  marginTop: 40,
  gap: 8,
  alignItems: "baseline",
});

const TypographyoLoginMain1 = styled("div")({
  display: "flex",
  marginTop: 40,
  justifyContent: "space-between",
});

const TypographyoLoginMain2 = styled("div")({
  display: "flex",
  gap: 8,
  alignItems: "baseline",
});

const CreateButton = styled(Button)({
  width: "100%",
  backgroundColor: customTheme.palette.grey.primary,
  marginTop: "40px",
  ...customFontVariant.palette.font16700,
  height: 56,
  textTransform: "none",
});

const ForgotButton = styled(Typography)({
  ...customFontVariant.palette.font18700,
  color: customTheme.palette.black.secondary,
});

const TypoDesc = styled(Typography)({
  ...customFontVariant.palette.font16400,
  color: customTheme.palette.black.secondary,
  maxWidth: 327,
  marginTop: 8,
});

const ErrorMsg = styled(Typography)({
  ...customFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const TermError = styled(Box)({
  height: 60,
  width: "100%",
  backgroundColor: customTheme.palette.red.light,
  borderLeft: `4px solid ${customTheme.palette.red.primary}`,
  marginTop: 40,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
});

const ErrorTypo = styled(Typography)({
  color: customTheme.palette.red.primary,
  ...customFontVariant.palette.font12400,
  padding: "12px 16px",
  maxWidth: 328,
});

// Customizable Area End

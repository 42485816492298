import React from "react";

// Customizable Area Start
import CustomHeader from "../../..//components/src/CustomHeader.web";
import Toast from "../../../components/src/CustomSnackbar.web";
import { appIcon } from "./assets";
// Customizable Area End

import HeaderWebController, { Props } from "./HeaderWebController";

export default class Header extends HeaderWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <CustomHeader
          navigation={this.handleLogInNavigation}
          navigateNotification={this.handleNavigationNotification}
          activeMenu={this.state.activeMenu}
          drawerOpen={this.state.drawerOpen}
          handleMenuClick={this.handleMenuClick}
          toggleDrawer={this.toggleDrawer}
          isAuthenticated={this.props.isAuthenticated ?? true}
          profile={this.state.profile}
          anchorEl={this.state.anchorEl}
          popoverOpen={this.state.popoverOpen}
          handleOpenPopover={this.handleOpenPopover}
          handleClosePopover={this.handleClosePopover}
          notificationData={this.state.allNotifications}
          notificationCount={this.state.notificationCount}
          markAllAsRead={this.props.markAllAsRead??this.markAllAsRead}
          loadingNotifications={this.state.loadingNotifications}
          isDisabled={this.props.isDisabled??this.state.isDisabled}
          timeSince={this.props.timeSince}
          data-test-id="headerComponent"
        />
        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          description={this.state.toastDescription}
          onClose={this.handleToastClose}
          duration={3000}
          position={{ vertical: "top", horizontal: "center" }}
          icon={<img src={appIcon} alt="icon" />}
          severity="success"
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import firebase from "../../../web/firebase";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  toastOpen: boolean;
  toastMessage: string;
  toastSeverity: "success" | "error" | "warning" | "info";
  email: string;
  password: string;
  emailError: boolean;
  passwordError: boolean;
  errorMsg: string;
  errorEmailFeild: string;
  errorPasswordFeild: string;
  passwordHide: boolean;
  activeMenu: string;
  drawerOpen: boolean;
  verified: boolean;
  verifyError: string;
  deviceToken: string;
  toastDescription: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  logInCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      toastOpen: false,
      toastMessage: "",
      toastSeverity: "success",
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
      errorMsg: "",
      errorEmailFeild: "",
      errorPasswordFeild: "",
      passwordHide: false,
      activeMenu: "0",
      drawerOpen: false,
      verified: false,
      verifyError: "",
      deviceToken: "",
      toastDescription: "",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.logInCallId) {
        const { email, password, account } = responseJson.errors?.[0] || {};
        const errorMsg = email || password || account;

        if (errorMsg) {
          this.setState({
            errorMsg,
            emailError: !!email,
            passwordError: !!password,
          });
        } else {
          setStorageData("id", responseJson.meta.id);
          setStorageData("token", responseJson.meta.token);
          setStorageData("profile", responseJson.meta.profile_image);
          await removeStorageData('redirected');
          this.props.navigation.navigate("LandingPage");
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const toastMessage = params.get("toast_message");

    if (toastMessage) {
      this.handleToastOpen(decodeURIComponent(toastMessage), "success");
    }

    await this.requestPermission();
  }

  async requestPermission() {
    const messaging = firebase.messaging();

    await messaging
      .getToken()
      .then(() => {
        return messaging.getToken();
      })
      .then((token) => {
        this.setState({ deviceToken: token });
      });
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  handleToastOpen = (
    message: string,
    severity: "success" | "error" | "warning" | "info" = "success"
  ) => {
    this.setState({
      toastMessage: message,
      toastSeverity: severity,
      toastOpen: true,
    });
  };

  handleToastClose = () => this.setState({ toastOpen: false });

  validateEmail = (email: string) => {
    const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/.test(
      email
    );

    this.setState({
      errorEmailFeild: isValid ? "" : "Invalid email",
      emailError: !isValid,
    });

    return isValid;
  };

  checkEmptyFields = () => {
    const { email, password, verified } = this.state;
    let isValid = true;

    if (!email) {
      this.setState({
        errorEmailFeild: "Email is required",
        emailError: true,
      });
      isValid = false;
    }

    if (!password) {
      this.setState({
        errorPasswordFeild: "Password is required",
        passwordError: true,
      });
      isValid = false;
    }

    if (!verified) {
      this.setState({
        verifyError: "Please complete the verification",
      });
      isValid = false;
    }

    return isValid;
  };

  handleEmailChange = (event: any) => {
    const newEmail = event.target.value;
    this.setState({
      email: newEmail,
      emailError: false,
      errorEmailFeild: "",
    });
  };

  handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    this.setState({
      password: newPassword,
      errorPasswordFeild: "",
      passwordError: false,
    });
  };

  handleNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handlePasswordHide = () =>
    this.setState({ passwordHide: !this.state.passwordHide });

  logInHandler = async () => {
    if (!this.checkEmptyFields()) return;
    if (!this.validateEmail(this.state.email)) return;

    if (Notification.permission === "granted") {
      await this.requestPermission();
    }

    const body = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password,
          device_id: this.state.deviceToken,
        },
      },
    };

    this.logInCallId = await this.apiCall({
      method: "POST",
      endPoint: "bx_block_login/logins",
      contentType: "application/json",
      body,
    });
  };

  handleMenuClick = (id: any) => {
    this.setState({ activeMenu: id });
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleCaptchaVerify = (verified: boolean, verifyError: string) => {
    this.setState({ verified, verifyError });
  };
  // Customizable Area End
}
import React from "react";
import { Snackbar, SnackbarOrigin } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import { makeStyles, Theme } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import { successIcon, errorIcon } from "./assets.web";
import customFontVariant from "./CustomFontVariant.web";
import customTheme from "./CustomTheme.web";

interface ToastProps {
  open: boolean;
  message: string;
  onClose?: (event?: React.SyntheticEvent, reason?: string) => void;
  severity?: "error" | "warning" | "info" | "success";
  duration?: number;
  position?: SnackbarOrigin;
  description?: string;
  icon?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    display: "flex",
    alignItems: "center",
    overflow: "auto",
    ...customFontVariant.palette.font16400,
    backgroundColor: customTheme.palette.white.main,
    color: customTheme.palette.grey.bold,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    fontSize: "0.9rem",
    ...customFontVariant.palette.font16400,
    color: customTheme.palette.grey.bold,
  },
}));

const iconMapping: Partial<
  Record<NonNullable<AlertProps["severity"]>, React.ReactNode>
> = {
  error: <img style={{ width: 26, height: 26 }} src={errorIcon} alt="icon" />,
  warning: <WarningIcon fontSize="inherit" />,
  info: <InfoIcon fontSize="inherit" />,
  success: (
    <img style={{ width: 26, height: 26 }} src={successIcon} alt="icon" />
  ),
};

const Toast: React.FC<ToastProps> = ({
  open,
  message,
  onClose,
  severity = "success",
  duration = 6000,
  position = { vertical: "bottom", horizontal: "center" },
  description,
  icon,
}) => {
  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={position}
    >
      <Alert
        severity={severity}
        elevation={6}
        variant="filled"
        className={classes.alert}
        icon={
          <div className={classes.iconContainer}>
            {icon || iconMapping[severity]}
          </div>
        }
      >
        {message}
        {description && (
          <div className={classes.description}>{description}</div>
        )}
      </Alert>
    </Snackbar>
  );
};

export default Toast;

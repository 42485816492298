import React from "react";

// Customizable Area Start
import Toast from "../../../components/src/CustomSnackbar.web";
import { Typography, styled, Box, Button } from "@material-ui/core";
import Header from "../../../components/src/CustomHeader.web";
import { background, eye, eyeOff, facebook, google, logoMain } from "./assets";
import customTheme from "../../../components/src/CustomTheme.web";
import customFontVariant from "../../../components/src/CustomFontVariant.web";
import Captcha from "../../../blocks/captcha/src/Captcha.web";
// Customizable Area End

import EmailAccountLoginWebController, {
  Props,
} from "./EmailAccountLoginWebController";

export default class EmailAccountLoginBlock extends EmailAccountLoginWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Wrapper1>
        <Header
          navigation={this.handleNavigation}
          activeMenu={this.state.activeMenu}
          drawerOpen={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
          handleMenuClick={this.handleMenuClick}
        />
        <BackGround1 />
        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          description={this.state.toastDescription}
          onClose={this.handleToastClose}
          severity={this.state.toastSeverity}
          duration={5000}
          position={{ vertical: "top", horizontal: "right" }}
          data-test-id="handleToastClose"
        />
        <MainContainer1>
          <Main1>
            <div style={{ textAlign: "center" }}>
              <img src={logoMain} alt="logoMain" />
            </div>
            <TypoTextItem1>Log in</TypoTextItem1>
            {this.state.errorMsg && (
              <TermError1>
                <ErrorTypo1>{this.state.errorMsg}</ErrorTypo1>
              </TermError1>
            )}
            <EmailMain1>
              <Label1>Email</Label1>
              <Input1
                type="email"
                placeholder="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                isError={this.state.emailError}
                data-test-id="handleEmailChange"
              />
              {this.state.errorEmailFeild && (
                <ErrorMsg1>{this.state.errorEmailFeild}</ErrorMsg1>
              )}
              <Label1>Password</Label1>
              <InputWrapper1>
                <Input1
                  type={this.state.passwordHide ? "text" : "password"}
                  placeholder="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  isError={this.state.passwordError}
                  data-test-id="handlePasswordChange"
                />
                <IconWrapper1 onClick={this.handlePasswordHide}>
                  {this.state.passwordHide ? (
                    <Eye1 src={eye} />
                  ) : (
                    <Eye1 src={eyeOff} />
                  )}
                </IconWrapper1>
              </InputWrapper1>
              {this.state.errorPasswordFeild && (
                <ErrorMsg1>{this.state.errorPasswordFeild}</ErrorMsg1>
              )}
              <ForgotButton1
                onClick={() => {
                  this.handleNavigation("ForgotPassword");
                }}
              >
                Forgot Password?
              </ForgotButton1>
            </EmailMain1>
            <div style={{ marginTop: 40 }}>
              <Captcha
                navigation={this.props.navigation}
                id={""}
                onVerify={this.handleCaptchaVerify}
                data-test-id="handleCaptchaVerify"
              />
            </div>
            {this.state.verifyError && <Error>{this.state.verifyError}</Error>}
            <CreateButton1
              variant="contained"
              size="large"
              onClick={this.logInHandler}
              data-test-id="logInHandler"
            >
              Log in
            </CreateButton1>
            <TypographyoLoginMain1>
              <TypographyoLogin1>Don’t have an account?</TypographyoLogin1>
              <Span1 onClick={() => this.handleNavigation("EmailAccountRegistrationWeb")}>
                Sign Up
              </Span1>
            </TypographyoLoginMain1>
            <GFIcon1>
              <img
                style={{ cursor: "pointer" }}
                src={facebook}
                alt="facebook"
              />
              <img style={{ cursor: "pointer" }} src={google} alt="google" />
            </GFIcon1>
          </Main1>
        </MainContainer1>
      </Wrapper1>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper1 = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const BackGround1 = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
  backgroundPosition: "center",
  zIndex: -1,
});

const Main1 = styled(Box)({
  padding: 40,
  backgroundColor: customTheme.palette.white.main,
  marginTop: "60px",
  marginBottom: "100px",
  maxWidth: "440px",
  borderRadius: 24,
  width: "100%",
});

const MainContainer1 = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const GFIcon1 = styled("div")({
  display: "flex",
  gap: 40,
  justifyContent: "center",
  marginTop: 40,
});

const TypographyoLogin1 = styled(Typography)({
  color: customTheme.palette.grey.secondary,
  ...customFontVariant.palette.font16400,
});

const TypographyoLoginMain1 = styled("div")({
  display: "flex",
  marginTop: 40,
  gap: 8,
  alignItems: "baseline",
});

const CreateButton1 = styled(Button)({
  width: "100%",
  backgroundColor: customTheme.palette.grey.primary,
  marginTop: "40px",
  ...customFontVariant.palette.font16700,
  height: 56,
  textTransform: "none",
});

const TermError1 = styled(Box)({
  height: 60,
  width: "100%",
  backgroundColor: customTheme.palette.red.light,
  borderLeft: `4px solid ${customTheme.palette.red.primary}`,
  marginTop: 40,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
});

const ErrorTypo1 = styled(Typography)({
  color: customTheme.palette.red.primary,
  ...customFontVariant.palette.font12400,
  padding: 12,
});

const ForgotButton1 = styled(Typography)({
  ...customFontVariant.palette.font14700,
  alignSelf: "end",
  marginTop: 8,
  cursor: "pointer",
});

const IconWrapper1 = styled("div")({
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});

const InputWrapper1 = styled("div")({
  position: "relative",
  width: "100%",
});

const Eye1 = styled("img")({
  color: customTheme.palette.grey.extraLight,
  width: 20,
});

const ErrorMsg1 = styled(Typography)({
  ...customFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const TypoTextItem1 = styled(Typography)({
  ...customFontVariant.palette.font24700,
  marginTop: 40,
});

const EmailMain1 = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: 26,
});

const Label1 = styled("label")({
  ...customFontVariant.palette.font14700,
  color: customTheme.palette.grey.secondary,
  marginBottom: 5,
  marginTop: 16,
});

const Input1 = styled("input")(({ isError }: any) => ({
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const Span1 = styled("span")({
  color: customTheme.palette.black.primary,
  ...customFontVariant.palette.font16700,
  cursor: "pointer",
});

const Error = styled(Typography)({
  ...customFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});
// Customizable Area End

import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import { styled } from "@material-ui/core";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import TextField from '@mui/material/TextField';
import Toast from "../../../components/src/CustomSnackbar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <div>
        <ContactUsHeader>Talk to us</ContactUsHeader>
        <Divider />
        <ContactUsSubHeader>Fill in the form below, and we will get back to you as soon as possible!</ContactUsSubHeader>
        <Box
          data-test-id="contactUs"
          component="form"
          noValidate
          onSubmit={this.handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            maxWidth: '100%',
            margin: 'auto',
            marginTop: "20px",
            mt: 5,
          }}
        >
          {
            this.FormFields.map((record) => {
              const { formError } = this.state;
              
              return (<>
                <FormLabel>{record.label}</FormLabel>
                {record.fieldName !== "details" && <TextField
                  data-test-id="contactUs-form"
                  name={record.fieldName}
                  type={record.fieldType}
                  variant="outlined"
                  placeholder={record.placeholder}
                  fullWidth
                  required={record.label.includes("*")}
                  onChange={this.handleChange}
                  error={!!this.state.formError?.[record.fieldName as keyof typeof formError]}
                  helperText={this.state.formError?.[record.fieldName as keyof typeof formError]}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #CBD5E1',
                        height: "56px",
                        borderRadius: "8px"
                      },
                      '&:hover fieldset': {
                        border: '1px solid #CBD5E1',
                      },
                      '&.Mui-focused fieldset': {
                        border: '1px solid #CBD5E1',
                      },
                    },
                  }} />}
                {record.fieldName === "details" && <TextField
                  name={record.fieldName}
                  placeholder={record.placeholder}
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  onChange={this.handleChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #CBD5E1',
                        borderRadius: "8px"
                      },
                      '&:hover fieldset': {
                        border: '1px solid #CBD5E1',
                      },
                      '&.Mui-focused fieldset': {
                        border: '1px solid #CBD5E1',
                      },
                    },
                  }}
                />}
              </>)
            })
          }
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ButtonSend type="submit">
              Send message
            </ButtonSend>
          </div>
        </Box>
        <Toast
          open={this.state.isContactUsToast}
          message={this.state.contactUsMsg}
          severity={"success"}
          onClose={this.handleContactUs}
          duration={3000}
          position={{ vertical: "bottom", horizontal: "center" }}
          data-test-id="contactUs-toaster"
        />
      </div>
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const ContactUsHeader = styled("div")({
  ...CustomFontVariant.palette.font24700,
  lineHeight: "32px"
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "30px 0px 25px",
});

const ContactUsSubHeader = styled("div")({
  ...CustomFontVariant.palette.font16400,
  color: '#334155',
  lineHeight: "24px",
});

const FormLabel = styled("p")({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  margin: "0"
})

const ButtonSend = styled('button')({
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
  backgroundColor: customTheme.palette.grey.primary,
  minWidth: '201px',
  border: 'none',
  borderRadius: '4px',
  padding: '10px 8px',
  marginTop: '20px',
  height: "56px"
});
// Customizable Area End

import React from "react";
// Customizable Area Start
import Header from "../../../blocks/landingpage/src/Header.web";
import { Button, CircularProgress, Grid, styled } from "@material-ui/core";
import Search from "../../../blocks/search/src/Search.web";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import { arrow, bubbles, doubleArrow } from "./assets";
import Footer from "../../../blocks/landingpage/src/Footer.web";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isAuthenticaed ? (
          <>
            <Header activeTab="1" navigation={this.props.navigation} id={""} />
            <Search navigation={this.props.navigation} id={""} />
          </>
        ) : (
          <>
            <Header
              activeTab="1"
              navigation={this.props.navigation}
              id={""}
              isAuthenticated={false}
            />
            {this.state.isLoading ? (
              <div
                style={{
                  margin: "50px auto",
                }}
              >
                <CircularProgress
                  size={25}
                  color={customTheme.palette.grey.primary}
                />
              </div>
            ) : (
              <>
                {!this.state.errors ? (
                  <>
                    <Main>
                      <Grid container spacing={8}>
                        <Grid item xs={12} md={6}>
                          <Heading>{this.state.allData?.Title?.title1}</Heading>
                          <SubHeading style={webStyle.mt30}>
                            {this.state.allData?.Title?.description1}
                          </SubHeading>
                          <ButtonOutline
                            variant="outlined"
                            style={webStyle.mt40}
                            data-test-id="handleNavigation"
                            onClick={() =>
                              this.handleNavigation("EmailAccountLoginBlock")
                            }
                          >
                            Explore Now
                            <img src={arrow} style={{ paddingLeft: 12 }} />
                          </ButtonOutline>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Img
                            src={this.state.allData?.Title?.image1_url}
                            alt="image"
                          />
                        </Grid>
                      </Grid>
                    </Main>
                    <MainAbout>
                      <Grid container spacing={8}>
                        <Grid item xs={12} md={6}>
                          <Img
                            src={this.state.allData?.About_Us?.image2_url}
                            alt="image"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Heading>
                            {this.state.allData?.About_Us?.title2}
                          </Heading>
                          <SubHeading style={webStyle.mt30}>
                            {this.state.allData?.About_Us?.description2}
                          </SubHeading>
                          <ButtonOutline
                            variant="outlined"
                            style={webStyle.mt40}
                            onClick={() =>
                              this.handleNavigation("NavigationMenu")
                            }
                          >
                            Read More
                            <img src={arrow} style={{ paddingLeft: 12 }} />
                          </ButtonOutline>
                        </Grid>
                      </Grid>
                    </MainAbout>
                    <MainProduct>
                      <img
                        src={bubbles}
                        alt="image"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: -30,
                          zIndex: -1,
                        }}
                      />
                      <img
                        src={doubleArrow}
                        alt="image"
                        style={{
                          position: "absolute",
                          right: 0,
                          top: -105,
                          zIndex: -1,
                        }}
                      />
                      <Grid container spacing={8}>
                        <Grid item xs={12} md={6}>
                          <HeadingProduct>
                            {this.state.allData?.Our_Product?.title3}
                          </HeadingProduct>
                          <SubHeadingProduct style={webStyle.mt30}>
                            {this.state.allData?.Our_Product?.subtitle}
                          </SubHeadingProduct>
                          <ul style={{ paddingLeft: 24 }}>
                            {this.state.allData?.Our_Product?.description3?.map(
                              (item: any, index: any) => (
                                <ListItem key={index}>
                                  <span
                                    style={{ position: "relative", left: 5 }}
                                  >
                                    {item}
                                  </span>
                                </ListItem>
                              )
                            )}
                          </ul>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ImgProduct
                            src={this.state.allData?.Our_Product?.image3_url}
                            alt="image"
                          />
                        </Grid>
                      </Grid>
                    </MainProduct>
                    <Footer navigation={this.props.navigation} id={""} />
                  </>
                ) : (
                  <DataNotFound>{this.state.errors}</DataNotFound>
                )}
              </>
            )}
          </>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mt30: {
    marginTop: 30,
  },
  mt40: {
    marginTop: 40,
  },
};

const Main = styled("div")({
  padding: "120px 100px",
  backgroundColor: customTheme.palette.grey.primary,
});

const MainAbout = styled("div")({
  padding: "120px 100px",
});

const MainProduct = styled("div")({
  padding: "100px 65px",
  position: "relative",
});

const Heading = styled("div")({
  ...CustomFontVariant.palette.font30700,
  lineHeight: "40px",
});

const SubHeading = styled("div")({
  ...CustomFontVariant.palette.font24400,
  lineHeight: "32px",
});

const HeadingProduct = styled("div")({
  ...CustomFontVariant.palette.font36700,
  lineHeight: "40px",
  zIndex: 1,
});

const SubHeadingProduct = styled("div")({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.grey.secondary,
});

const Img = styled("img")({
  width: "100%",
  objectFit: "cover",
  borderRadius: 24,
  height: 310,
});

const ImgProduct = styled("img")({
  width: "100%",
  objectFit: "cover",
  height: 420,
});

const ButtonOutline = styled(Button)({
  padding: "10px 60px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  borderColor: customTheme.palette.black.primary,
});

const ListItem = styled("li")({
  ...CustomFontVariant.palette.font18400,
  lineHeight: "26px",
  marginTop: 30,
  "&::marker": {
    fontSize: "0.8em",
    color: customTheme.palette.grey.secondary,
  },
});

const DataNotFound = styled("div")({
  margin: 40,
  display: "flex",
  justifyContent: "center",
  color: customTheme.palette.grey.secondary,
  ...CustomFontVariant.palette.font20700,
});

// Customizable Area End

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string;
  password: string;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  isMinLength: boolean;
  termError: string;
  emailError: string;
  passwordError: string;
  toastOpen: boolean;
  toastMessage: string;
  toastSeverity: "success" | "error" | "warning" | "info";
  checked: boolean;
  passwordHide: boolean;
  activeMenu: string;
  drawerOpen: boolean;
  firstAttempt: boolean;
  name: string;
  nameError: string;
  verified:boolean;
  verifyError:string;
  isPolicyModalOpen: boolean;
  privacyConcernText: string;
  isAgree: boolean,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpCallId: string = "";
  getPrivacyConcernApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      email: "",
      password: "",
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      isMinLength: false,
      termError: "",
      emailError: "",
      passwordError: "",
      toastOpen: false,
      toastMessage: "",
      toastSeverity: "success",
      checked: false,
      passwordHide: false,
      activeMenu: "0",
      drawerOpen: false,
      firstAttempt: false,
      name: "",
      nameError: "",
      verified:false,
      verifyError:"",
      isPolicyModalOpen: false,
      privacyConcernText: "",
      isAgree: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.signUpCallId) {
        if (responseJson.errors) {
          this.setState({ emailError: responseJson.errors[0].account });
        } else {
          this.setState({
            email: "",
            password: "",
            checked: false,
            hasUpperCase: false,
            hasLowerCase: false,
            hasNumber: false,
            isMinLength: false,
          });
          this.handleToastOpen(
            configJSON.ToastRegisterdMSG,
            configJSON.toastSuccessType
          );
        }
      }

      if (apiRequestCallId === this.getPrivacyConcernApiCallId && responseJson.data) {
        this.setState({privacyConcernText: responseJson.data[0].description})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
   this.getPrivacyText()
  }
  
  apiCall = async (data: any) => {
    const signUpToken = await getStorageData("token");
    const { contentType, method, endPoint, body, type } = data;
    const header = { "Content-Type": contentType, 'token': signUpToken };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData"
      ? request.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  validateEmail = (email: string) => {
    const isValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/.test(
      email
    );
    this.setState({
      emailError: isValid ? "" : "Invalid email",
    });
    return isValid;
  };

  validatePassword = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isMinLength = password?.length >= 8;

    this.setState({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      isMinLength,
    });
  };

  handleEmailChange = (event: any) => {
    const newEmail = event.target.value;
    this.setState({ email: newEmail, emailError: "" });
  };

  handleNameChange = (event: any) => {
    const name = event.target.value;
    this.setState({ name, nameError: "" });
  };

  handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    this.setState({ password: newPassword, passwordError: "" });
    this.validatePassword(newPassword);
  };

  handleCheckboxChange = () => {
    this.setState({ checked: !this.state.checked, isAgree: false});
  };

  validateName = (name: any, firstAttempt: any) => {
    const errors = { nameError: "" };
    let isValid = true;

    if (!name) {
      errors.nameError = firstAttempt
        ? "Please enter your name"
        : "Name is required";
      isValid = false;
    } else if (!/^[a-zA-Z\s]*$/.test(name)) {
      errors.nameError = "Name can only contain letters and spaces";
      isValid = false;
    }

    return { errors, isValid };
  };

  signUpHandler = async () => {
    const {
      name,
      email,
      password,
      hasLowerCase,
      hasNumber,
      hasUpperCase,
      isMinLength,
      checked,
      firstAttempt,
      verified,
      isAgree
    } = this.state;
    const errors = {} as any;
    let isValid = true;

    const nameValidation = this.validateName(name, firstAttempt);
    Object.assign(errors, nameValidation.errors);
    isValid = nameValidation.isValid;
    isValid = this.validateEmailField(email, firstAttempt, errors) && isValid;

    if (!password) {
      errors.passwordError = firstAttempt
        ? "Please enter password"
        : "Password is required";
      isValid = false;
    } else if (!hasUpperCase || !hasLowerCase || !hasNumber || !isMinLength) {
      errors.passwordError = configJSON.passwordError;
      isValid = false;
    }

    if (!checked || !isAgree) {
      errors.termError = firstAttempt
        ? "Please agree to terms"
        : "You have to agree with Privacy Policy and Terms and Conditions to Sign up.";
      isValid = false;
    }

    if (!verified) {
      errors.verifyError = "Please complete the verification";
      isValid = false;
    }

    if (!isValid) {
      this.setState({ ...errors, firstAttempt: false });
      return;
    }

    const body = {
      data: {
        type: "email_account",
        attributes: {
          full_name: this.state.name,
          email: this.state.email,
          password: this.state.password,
        },
      },
    };

    this.signUpCallId = await this.apiCall({
      method: "POST",
      endPoint: "account_block/accounts",
      contentType: "application/json",
      body,
    });
  };

  getPrivacyText = async () => {
    this.getPrivacyConcernApiCallId = await this.apiCall({
      method: "GET",
      endPoint: "/bx_block_terms_and_conditions/terms_and_conditions/get_terms_and_condition",
      contentType: "application/json",
    });
  }

  validateEmailField = (email: string, firstAttempt: boolean, errors: any): boolean => {
    if (!email) {
      errors.emailError = firstAttempt ? "Please enter email" : "Email is required";
      return false;
    }
    if (!this.validateEmail(email)) {
      errors.emailError = "Invalid email";
      return false;
    }
    return true;
  };

  handleToastOpen = (
    message: string,
    severity: "success" | "error" | "warning" | "info" = "success"
  ) => {
    this.setState({
      toastMessage: message,
      toastSeverity: severity,
      toastOpen: true,
    });
    setTimeout(() => {
      this.handleLogInNavigation("EmailAccountLoginBlock");
    }, 3000);
  };

  handleToastClose = () => {
    this.setState({ toastOpen: false });
  };

  handleLogInNavigation = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handlePasswordHide = () => {
    this.setState({ passwordHide: !this.state.passwordHide });
  };

  handleMenuClick = (id: any) => {
    this.setState({ activeMenu: id });
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleCaptchaVerify = (verified: boolean, verifyError:string) => {
    this.setState({ verified, verifyError });
  };
  
  // Customizable Area End
}

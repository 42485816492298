import React from "react";

// Customizable Area Start
import { Box, Button, Checkbox, Typography, styled } from "@material-ui/core";
import Header from "../../../components/src/CustomHeader.web";
import {
  backgroundImg,
  checkbox,
  checkboxError,
  eye,
  eyeOff,
  facebook,
  google,
  logoMain,
  right,
  unchecked,
  wrong,
  privacyCheckbox,
} from "./assets";
import customTheme from "../../../components/src/CustomTheme.web";
import Toast from "../../../components/src/CustomSnackbar.web";
import customFontVariant from "../../../components/src/CustomFontVariant.web";
import Captcha from "../../../blocks/captcha/src/Captcha.web";
import CustomModal from "../../../components/src/CustomModal.web";
// Customizable Area End

import EmailAccountRegistrationWebController, {
  Props,
} from "./EmailAccountRegistrationWebController";

export default class EmailAccountRegistration extends EmailAccountRegistrationWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPrivacyModal = () => {
    return (
      <CustomModal
        open={this.state.isPolicyModalOpen}
        title="Terms and Conditions"
        paperWidth={"786px"}
        doneButtonText="Agree"
        cancelButtonText="Cancel"
        onShowCloseIcon={() => this.setState({ isPolicyModalOpen: false })}
        onCancel={() => this.setState({ isPolicyModalOpen: false })}
        onDone={() => this.setState({ isPolicyModalOpen: false, isAgree: true, termError: "" })}
        disableBtn={!this.state.checked}
        data-test-id="handlePrivacyDialog"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: '30px'
          }}
        >
          <div style={{ ...customFontVariant.palette.font18400, lineHeight: '26px' }} dangerouslySetInnerHTML={{ __html: this.state.privacyConcernText }} />
          <div style={{ display: "flex" }}>
            <CheckboxMain
              checked={this.state.checked}
              disableFocusRipple
              disableRipple
              icon={
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={unchecked}
                />
              }
              checkedIcon={<img src={privacyCheckbox} alt="logo" />}
              onChange={this.handleCheckboxChange}
              data-test-id="handlePrivacyCheckboxChange"
              />
            <div style={{ width: '100%', marginTop: "40px" }}>
              <span style={{ color: customTheme.palette.black.primary, ...customFontVariant.palette.font18700, }}>I have read and agree to these Terms and Conditions</span>
            </div>
          </div>
        </div>
      </CustomModal>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Wrapper>
        <Header
          navigation={this.handleLogInNavigation}
          activeMenu={this.state.activeMenu}
          drawerOpen={this.state.drawerOpen}
          handleMenuClick={this.handleMenuClick}
          toggleDrawer={this.toggleDrawer}
        />
        <BackGround />
        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          onClose={this.handleToastClose}
          severity={this.state.toastSeverity}
          duration={5000}
          position={{ vertical: "top", horizontal: "right" }}
          data-test-id="handleToastClose"
        />
        <MainContainer>
          <Main>
            <div style={{ textAlign: "center" }}>
              <img src={logoMain} alt="logoMain" />
            </div>
            <TypoTextItem>Sign Up</TypoTextItem>
            {this.state.termError && (
              <TermError>
                <ErrorTypo>{this.state.termError}</ErrorTypo>
              </TermError>
            )}
            <EmailMain>
              <Label>Name</Label>
              <Input
                type="text"
                placeholder="name"
                value={this.state.name}
                onChange={this.handleNameChange}
                isError={this.state.nameError}
                data-test-id="handleNameChange"
                maxLength={30}
              />
              {this.state.nameError && (
                <ErrorMsg>{this.state.nameError}</ErrorMsg>
              )}
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                isError={this.state.emailError}
                data-test-id="handleEmailChange"
              />
              {this.state.emailError && (
                <ErrorMsg>{this.state.emailError}</ErrorMsg>
              )}
              <Label>Password</Label>
              <InputWrapper>
                <Input
                  type={this.state.passwordHide ? "text" : "password"}
                  placeholder="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  isError={this.state.passwordError}
                  data-test-id="handlePasswordChange"
                />
                <IconWrapper onClick={this.handlePasswordHide}>
                  {this.state.passwordHide ? (
                    <Eye src={eye} />
                  ) : (
                    <Eye src={eyeOff} />
                  )}
                </IconWrapper>
              </InputWrapper>

              {this.state.passwordError && (
                <ErrorMsg>{this.state.passwordError}</ErrorMsg>
              )}
            </EmailMain>
            <HeadingPassword>Your password must contain</HeadingPassword>
            <div>
              <PasswordMain>
                <PasswordCheckImg
                  src={this.state.hasUpperCase ? right : wrong}
                />
                <PasswordCheck>At least one capital letter</PasswordCheck>
              </PasswordMain>
              <PasswordMain>
                <PasswordCheckImg
                  src={this.state.hasLowerCase ? right : wrong}
                />
                <PasswordCheck>At least one lowercase letter</PasswordCheck>
              </PasswordMain>
              <PasswordMain>
                <PasswordCheckImg src={this.state.hasNumber ? right : wrong} />
                <PasswordCheck>At least one number</PasswordCheck>
              </PasswordMain>
              <PasswordMain>
                <PasswordCheckImg
                  src={this.state.isMinLength ? right : wrong}
                />
                <PasswordCheck>
                  Minimum character length is 8 characters
                </PasswordCheck>
              </PasswordMain>
            </div>
            <div style={{ marginTop: 40 }}>
              <Captcha
                navigation={this.props.navigation}
                id={""}
                onVerify={this.handleCaptchaVerify}
                data-test-id="handleCaptchaVerify"
              />
            </div>
            {this.state.verifyError && (
              <ErrorMsg>{this.state.verifyError}</ErrorMsg>
            )}
            <div style={{ display: "flex" }}>
              <CheckboxMain
                checked={this.state.checked && this.state.isAgree}
                disableFocusRipple
                disableRipple
                icon={
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={this.state.termError ? checkboxError : unchecked}
                  />
                }
                checkedIcon={<img src={checkbox} alt="logo" />}
                onChange={() => this.setState({ isPolicyModalOpen: true })}
                data-test-id="handleCheckboxChange"
              />
              <TermMain>
                <Term>I hereby consent to the</Term>
                <Span> Privacy Policies </Span>
                <Term>and</Term>
                <Span> Terms Of Use </Span>
              </TermMain>
              {this.renderPrivacyModal()}
            </div>
            <CreateButton
              variant="contained"
              size="large"
              onClick={this.signUpHandler}
              data-test-id="signup"
            >
              Create Account
            </CreateButton>
            <TypographyoLoginMain>
              <TypographyoLogin>Already have an account?</TypographyoLogin>
              <Span1
                onClick={() =>
                  this.handleLogInNavigation("EmailAccountLoginBlock")
                }
              >
                Log In
              </Span1>
            </TypographyoLoginMain>
            <GFIcon>
              <img
                style={{ cursor: "pointer" }}
                src={facebook}
                alt="facebook"
              />
              <img style={{ cursor: "pointer" }} src={google} alt="google" />
            </GFIcon>
          </Main>
        </MainContainer>
      </Wrapper>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const BackGround = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
  backgroundPosition: "center",
  zIndex: -1,
});

const Main = styled(Box)({
  padding: 40,
  backgroundColor: customTheme.palette.white.main,
  marginTop: "60px",
  marginBottom: "100px",
  maxWidth: "440px",
  borderRadius: 24,
  width: "100%",
});

const MainContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const TypoTextItem = styled(Typography)({
  ...customFontVariant.palette.font24700,
  marginTop: 40,
});

const EmailMain = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: 26,
});

const Label = styled("label")({
  ...customFontVariant.palette.font14700,
  color: customTheme.palette.grey.secondary,
  marginBottom: 5,
  marginTop: 16,
});

const Input = styled("input")(({ isError }: any) => ({
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const HeadingPassword = styled(Typography)({
  ...customFontVariant.palette.font16400,
  marginTop: 40,
  color: customTheme.palette.grey.bold,
});

const Term = styled("label")({
  ...customFontVariant.palette.font12400,
});

const Span = styled("span")({
  color: customTheme.palette.black.primary,
  ...customFontVariant.palette.font14700,
  cursor: "pointer",
});

const Span1 = styled("span")({
  color: customTheme.palette.black.primary,
  ...customFontVariant.palette.font16700,
  cursor: "pointer",
});

const PasswordCheck = styled(Typography)({
  ...customFontVariant.palette.font12400,
});

const PasswordCheckImg = styled("img")({
  height: 12,
  width: 12,
});

const PasswordMain = styled("div")({
  display: "flex",
  gap: 10,
  alignItems: "center",
  marginTop: 8,
});

const GFIcon = styled("div")({
  display: "flex",
  gap: 40,
  justifyContent: "center",
  marginTop: 40,
});

const TypographyoLogin = styled(Typography)({
  color: customTheme.palette.grey.secondary,
  ...customFontVariant.palette.font16400,
});

const TypographyoLoginMain = styled("div")({
  display: "flex",
  marginTop: 40,
  gap: 8,
  alignItems: "baseline",
});

const CreateButton = styled(Button)({
  width: "100%",
  backgroundColor: customTheme.palette.grey.primary,
  marginTop: "40px",
  ...customFontVariant.palette.font16700,
  height: 56,
  textTransform: "none",
});

const TermMain = styled("div")({
  width: 300,
  marginTop: 40,
});

const TermError = styled(Box)({
  height: 60,
  width: "100%",
  backgroundColor: customTheme.palette.red.light,
  borderLeft: `4px solid ${customTheme.palette.red.primary}`,
  marginTop: 40,
  borderRadius: 4,
});

const ErrorTypo = styled(Typography)({
  color: customTheme.palette.red.primary,
  ...customFontVariant.palette.font12400,
  padding: "12px 16px",
  maxWidth: 328,
});

const CheckboxMain = styled(Checkbox)({
  paddingLeft: 0,
  marginTop: 32,
  height: 20,
  width: 20,
  borderRadius: "6px",
  color: customTheme.palette.red.secondary,
  backgroundColor: customTheme.palette.white.primary,
});

const ErrorMsg = styled(Typography)({
  ...customFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const IconWrapper = styled("div")({
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});

const InputWrapper = styled("div")({
  position: "relative",
  width: "100%",
});

const Eye = styled("img")({
  color: customTheme.palette.grey.extraLight,
  width: 20,
});

// Customizable Area End
